<template>
  <!-- <v-hover v-slot="{ hover }"> -->
    <v-card
      @click="openVideo()"
      class="io-video"
      :ripple="false"
      style="width: 100%;"
    >
      <v-img
        v-if="video.mp4_url"
        :src="thumbnail"
        :lazy-src="video.lazy_thumb_url ? video.lazy_thumb_url.replace('http', 'https') : ''"
        >
      </v-img>
      <v-responsive v-else :aspect-ratio="16/9">
        <v-card class="text-center pt-5" flat color="primary" style="height: 100%;">
          <div class="white--text mt-5">Processing video</div>
          <v-progress-linear
            v-if="!video.encoded"
            color="white"
            stream
            indeterminate
            class="mx-auto mt-2"
            style="width: 80%;"
          ></v-progress-linear>
          <div class="white--text mt-2">Video can still be viewed</div>

          <!-- <h1 class="white--text mt-4">{{video.encoding_percentage}}%</h1> -->
        </v-card>
      </v-responsive>
      <v-card-text style="height: 50px" class="py-1">
        <div style="text-align: center;">
          <strong>
            {{video.title}}
          </strong>
        </div>
      </v-card-text>
      <v-menu
        offset-y
        class="mt-2"
        dense
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            x-small
            absolute
            @click.stop=""
            style="top: 5px; right: 5px;"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            dense
            @click.prevent="startRename()"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('rename') }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="deleting = true"
            v-if="video.encoded"
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="deleting = true"
            v-else
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('videos.cancel_processing') }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="mt-3">
            <share-btn resource_type="video" :resource_id="video.id"></share-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <ConfirmationDialog
        :show="deleting"
        :subText="$t('videos.confirm_delete_long', [video.title])"
        btnColor="red"
        :btnText="$t('delete')"
        :text="$t('videos.confirm_delete')"
        @decline="deleting = false"
        @accept="acceptDeleting()"
      />
      <v-dialog
        :value="renaming"
        max-width="400"
        @click:outside="renaming = false"
      >
        <v-card class="pt-10 pb-3">
          <v-card-text>
            <v-form
              :onSubmit="rename"
            ></v-form>
            <v-text-field
              v-model="newName"
              autofocus
              solo
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="renaming = false; newName = video.title"
            >{{$t('cancel')}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn 
              color="primary"
              :disabled="!newName.length || newName == video.title"
              @click="rename()"
            >{{$t('rename')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  <!-- </v-hover> -->
</template>

<script>
import ConfirmationDialog from '@/components/ConfirmationDialog'
import ShareBtn from '@/components/share/ShareBtn'

import { mapActions } from 'vuex'

export default {
  props: ['video'],
  components: { ConfirmationDialog, ShareBtn },
  data: () => ({
    deleting: false,
    renaming: false,
    newName: ''
  }),
  methods: {
    ...mapActions('videos', [
      'deleteVideo',
      'updateVideo'
    ]),
    ...mapActions('noti', [
      'error',
      'info',
      'success'
    ]),
    openVideo() {
      this.$router.push(`/videos/${this.video.id}`)
    },
    acceptDeleting() {
      this.deleteVideo(this.video.id)
        .then(() => this.info(this.$t('videos.video_deleted')))
        .catch(e => {
          this.error(e)
        })
    },
    startRename() {
      this.$nextTick(() => {
        this.renaming = true
      })
      this.newName = this.video.title
    },
    rename(e) {
      if(e) e.preventDefault();
      
      if(!this.newName.length) return

      if(this.newName === this.video.title) {
        this.renaming = false
        return
      }

      this.updateVideo({
        ...this.video,
        title: this.newName
      })
      .then(() => {
        this.info(this.$t('videos.title_changed'))
      })
      .catch(e => this.error(e))
      .finally(() => {
        this.renaming = false
      })
    }
  },
  computed: {
    thumbnail() {
      if(this.video.thumb_url.includes('https')) return this.video.thumb_url
      return this.video.thumb_url.replace('http', 'https')
    }
  }
}
</script>