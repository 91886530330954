<template>
    <v-dialog v-model="ModalOpen" width="600">
        <v-card>
            <v-card-title>
                Syötä striimin nimi
            </v-card-title>
            <v-card-text>
                <v-text-field ref="titleField" v-model="streamName" label="Striimin nimi"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="closeModal">Peruuta</v-btn>
                <v-btn @click="goToStream" color="primary">GO</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
  <script>
  import {mapActions, mapGetters} from 'vuex'
  export default {
    mounted() {
      this.$refs.titleField.focus();
    },
    data() {
      return {
        streamName: '',
      };
    },
    computed: {
        ...mapGetters('streaming', [
            'isModalOpen'
        ]),
      ModalOpen: {
        get() {
          return this.isModalOpen;
        },
        set(value) {
          if (!value) {
            this.closeModal();
          }
        },
      },
    },
    methods: {
        ...mapActions('streaming', [
            'closeModal',
            'openModal',
            'setStreamName'
        ]),
        goToStream() {
            this.setStreamName(this.streamName)
            this.closeModal()
            if(this.$vuetify.breakpoint.mobile) this.$router.replace('/streaming/mobile');
            else this.$router.replace('/streaming');
        },
    },
};
</script>