<template>
  <div style="width: 100%;">
    <div :class="{'mt-8': $vuetify.breakpoint.mobile, 'dark': $vuetify.theme.dark}" style="max-width: 1200px; padding: 30px 30px 80px 30px; height: 100%; margin: 0 auto;">
      <v-row class="m-auto">
        <v-menu class="mt-0" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large 
              color="primary" 
              v-bind="attrs"
              v-on="on"
              fab
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="dialog = true; track('Opened add video dialog')">
              <v-list-item-icon>
                <v-icon class="mr-3">
                  mdi-plus
                </v-icon>
                <v-list-item-title>
                  {{ $t('videos.add_video') }}
                </v-list-item-title>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item v-if="currentTeam.streaming_enabled == 1" @click="openModal()">
              <v-list-item-icon>
                <v-icon class="mr-3">
                  mdi-plus
                </v-icon>
                <v-list-item-title>
                  {{ $t('streaming.stream') }}
                </v-list-item-title>
              </v-list-item-icon>
            </v-list-item>
            <!-- <v-list-item @click="yt_dialog = true; track('Opened add from YouTube dialog')">
              <v-list-item-icon>
                <v-icon class="mr-3">
                  mdi-plus
                </v-icon>
                <v-list-item-text>
                  Add from YouTube
                </v-list-item-text>
              </v-list-item-icon>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <v-col md="4">
          <v-text-field
            @input="setFilter($event)"
            :value.sync="filter"
            filled
            rounded
            dense
            :placeholder="$t('search')"
            prepend-inner-icon="mdi-magnify"
            :append-icon="filter ? 'mdi-close' : ''"
            @click:append="setFilter('')"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <Loader v-if="loading"></Loader>
      <!-- <div style="z-index: -1; position: static;" id="checklist"></div> -->
      <div
        style="text-align: center;"
        v-if="videos.length <= 1 && !loading && only_samples"
        class="pt-10"
      >
        <v-row>
          <v-col cols="12" md="6">
            <div>
              <div class="text-h4 mb-10">
                {{ $t('videos.welcome_to_tiimi') }}, {{ userFirstNamePretty }}!
              </div>
              <!-- <div>
                <v-img class="ml-8" height="300" contain src="@/assets/svg/videos.svg"></v-img>
              </div> -->
              <div class="text-h6 mt-6">
                {{ $t('videos_are_the_backbone') }}
              </div>
              <div class="mt-2">
                {{ $t('start_analyzing_by_uploading') }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div style="display: flex; flex-direction: column; width: 100%; height: 80%; justify-content: center; align-content: center;">
              <v-btn
                large
                id="large-add-a-video-btn"
                color="primary" 
                class="mt-6 mx-auto"
                rounded
                width="300"
                @click="dialog = true; track('Opened add video dialog')"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $t('videos.add_video') }}
              </v-btn>
              <br>
              <!-- <div class="my-8">
                OR - try this sample video!
              </div>
              <div style="display: flex; justify-content: center;">
                <div style="width: 300px;">
                  <VideoCard
                    :video="videos[0]"
                  />
                </div>
              </div> -->
              <!-- <v-btn
                @click="yt_dialog = true; track('Opened add from YouTube dialog')"
                large 
                color="primary" 
                rounded
                width="400"
                class="mt-6 mx-auto"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $t('videos.add_video_from_yt') }}
              </v-btn> -->
            </div>
            <!-- <v-card-text>
              <h1 color="primary" class="mb-8">
                {{ $t('videos.start_by_adding') }}
              </h1>
              {{ $t('videos.start_by_adding_long') }}
            </v-card-text> -->
          </v-col>
        </v-row>
      </div>
      <v-row height="100%;" v-else>
        <v-col 
          v-for="(video, i) in filtered_videos"
          sm="6" md="4" lg="3" 
          :key="video.id + i"
        >
          <VideoCard
            :video="video"
          />
        </v-col>
      </v-row>
      <v-dialog
        v-if="dialog"
        v-model="dialog"
        width="600"
        :fullscreen="this.$vuetify.breakpoint.mobile"
        persistent
      >
        <Dashboard
          @close="dialog = false" 
          :options="uppyOptions"
          :yt_download_url="yt_download_url"
          :video_title="yt_video_title"
        ></Dashboard>
      </v-dialog>
      <v-dialog 
        v-model="yt_dialog"
        width="600"
      >
        <v-card class="pt-5">
          <v-card-title>
            Import video from YouTube
          </v-card-title>
          <v-card-text>
            Just copy and paste a YouTube link :)
          </v-card-text>
          <v-card-text>
            <v-text-field v-model="yt_link" solo rounded placeholder="YouTube link"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loading_yt" @click="downloadFromYoutube()" >Download</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <StreamTitle v-if="isModalOpen"></StreamTitle>
    </div>
  </div>
</template>

<script>

import Dashboard from '@/components/upload/Dashboard'
import VideoCard from '@/components/video/VideoCard'
import { mdiMagnify } from "@mdi/js";
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Loader from '@/components/video/Loader'
import StreamTitle from '../components/streaming/StreamTitle.vue'

export default {
  components: { Dashboard, Loader, VideoCard, StreamTitle },
  data: () => ({
    icons: { mdiMagnify },
    height: 200,
    uppyOptions: {
      proudlyDisplayPoweredByUppy: false,
      companionUrl: 'http://localhost:4040/companion',
      debug: false,
      restrictions: {
        maxFileSize: null,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: null,
        allowedFileTypes: ['video/*'],
      },
      server: {
        host: 'localhost:4040', // or yourdomain.com
        protocol: 'http'
      },
      hideUploadButton: true,
    },
    dialog: false,
    yt_dialog: false,
    yt_link: '',
    yt_download_url: '',
    yt_video_title: '',
    loading_yt: false,
    add_checklist_interval: null
  }),
  created() {
    if(this.$route.query.token) {
      this.initUser(this.$route.query.token)

      this.$nextTick(() => {
        this.$router.push('/videos')
      })
    }

    if(this.currentTeamId) {
      this.initVideos(this.currentTeamId)
        .then(() => {
          if(this.$route.query?.action == 'open-some') {
            if(this.videos.length) {
              this.$router.push(`/videos/${this.videos[0].id}?sidebar=clipper`)
            } else {
              this.dialog = true
            }
          }
        })
    }
    if(this.$route.query?.view == 'add-video') {
      this.dialog = true
    }

    if(this.$route.query.fanfare === true || this.$route.query.fanfare === 'true') {
      this.$router.replace({ path: '/videos' })
      this.$confetti.start();

      setTimeout(() => {
        this.$confetti.stop();
      }, 2000)
    }

    this.track('Opened videos')
  },
  mounted() {
    // this.add_checklist_interval = setInterval(() => {
    //   if(window.productFruits?.api?.checklists) {
    //     window.productFruits.api.checklists.injectToElement(1635, document.getElementById("checklist"));
    //     clearInterval(this.add_checklist_interval)
    //   } 
    // }, 200)
  },
  methods: {
    ...mapMutations('videos', [
      'setFilter'
    ]),
    ...mapActions('videos', [
      'initVideos',
      'getYtUrl'
    ]),
    ...mapActions('user', [
      'initUser',
    ]),
    ...mapActions('noti', [
      'error'
    ]),
    ...mapActions('streaming', [
      'openModal'
    ]),
    downloadFromYoutube() {
      this.loading_yt = true
      // let id = ytdl.getURLVideoID(this.yt_link)

      this.getYtUrl(this.yt_link)
        .then(e => {
          this.yt_download_url = e.url
          this.yt_video_title = e.title
          this.$nextTick(() => {
            this.yt_dialog = false
            this.dialog = true
          })
        })
        .catch(e => {
          this.error(e)
        })
        .finally(() => {
          this.loading_yt = false
        })
    }
  },
  computed: {
    filtered_videos() {
      if(!this.filter) return this.videos
      return this.videos.filter(video => video.title.toLowerCase().includes(this.filter.toLowerCase()))
    },
    only_samples() {
      return !this.videos.find(v => !v.sample_video)
    },
    ...mapGetters('videos', [
      'videos',
      'filter',
      'loading'
    ]),
    ...mapGetters('user', [
      'currentTeamId',
      'currentTeam',
      'currentPlanId',
      'user',
      'current_sport_id',
      'userFirstNamePretty'
    ]),
    ...mapGetters('streaming', [
      'isModalOpen'
    ])
  },
  watch: {
    '$route.query': {
      handler() {
        if(this.$route.query?.view == 'add-video') {
          this.dialog = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dark {
  background-color: #121212;
}
  .io-video {
    transition-duration: 0.3s;
  }

  .on-hover {
    cursor: pointer;
  }
</style>