<template>
  <v-dialog 
    :value="value"
    width="500"
    @click:outside="$emit('input', false)"
  >
    <v-card>
      <v-card-title>
        Upload hours used!
      </v-card-title>
      <v-card-text v-if="!currentTeam.is_the_best">
        Your current plan allows for {{currentTeam.total_hours_saved}} hours
        of uploaded video. Upgrade to keep on going!
      </v-card-text>
      <v-card-text v-if="currentTeam.is_the_best">
        Try deleting older videos to create space!
      </v-card-text>
      <v-card-text v-else-if="isOrderer">
        <UpgradeButton
          :expanded="true"
        ></UpgradeButton>
      </v-card-text>
      <v-card-text v-else>
        Contact your team owner to upgrade your account.
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapGetters } from 'vuex'
import  UpgradeButton from '@/components/navigation/UpgradeButton'
export default {
  props: ['value'],
  components: { UpgradeButton },
  computed: {
    ...mapGetters('user', [
      'currentTeam',
      'isOrderer'
    ])
  }
}
</script>